import React from "react";
import Container from "../components/Container";
import Meme from "../components/Meme";
import Title from "../components/Title";

function Albee() {
  return (
    <Container>
      <Title>Happy Valentine's day A&#38;A!</Title>
      <Meme url="https://firebasestorage.googleapis.com/v0/b/verysuspiciouslink-feb4f.appspot.com/o/travis.jpg?alt=media&token=c3662885-7a87-42fb-ae40-35a6c1ed792b" />
    </Container>
  )
}

export default Albee;
