import React from "react";
import Container from "../components/Container";
import Meme from "../components/Meme";
import Title from "../components/Title";

function SikhWedding() {
  return (
    <Container>
      <Meme url="https://firebasestorage.googleapis.com/v0/b/verysuspiciouslink-feb4f.appspot.com/o/sikh-wedding.jpg?alt=media&token=82a7615a-55d2-4d4b-bb27-29854d078817" />
    </Container>
  )
}

export default SikhWedding;
