import React from "react";
import Container from "../components/Container";
import Meme from "../components/Meme";
import Title from "../components/Title";

function Vite() {
  return (
    <Container>
      <Title>Happy Valentine's day Vite team!</Title>
      <Meme url="https://firebasestorage.googleapis.com/v0/b/verysuspiciouslink-feb4f.appspot.com/o/tom.jpg?alt=media&token=c0c0c5d6-7ae4-4913-8c07-a9c859d0d85e" />
      <Meme url="https://firebasestorage.googleapis.com/v0/b/verysuspiciouslink-feb4f.appspot.com/o/jade.jpg?alt=media&token=b1de93f1-31c0-4015-ab2a-54b4ec61668e" />
      <Meme url="https://firebasestorage.googleapis.com/v0/b/verysuspiciouslink-feb4f.appspot.com/o/james.jpg?alt=media&token=10c2c59c-4176-448c-9120-110fcb7018c1" />
    </Container>
  )
}

export default Vite;
