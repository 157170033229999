import React from "react";
import Container from "../components/Container";
import Title from "../components/Title";

function Cruise2024() {
  return (
    <Container>
      <Title>water you doing here in July 2024 - more coming soon!</Title>
    </Container>
  );
}

export default Cruise2024;
