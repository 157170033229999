import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import Squirrel from "./pages/Squirrel";
import Vite from "./pages/Vite";
import Albee from "./pages/Albee";
import SikhWedding from "./pages/SikhWedding";
import ViteRamenJokes1 from "./pages/ViteRamenJokes1";
import Cruise2024 from "./pages/Cruise2024";

const styles = {
  display: "flex",
  width: "100%",
  height: "100%",
};

function App() {
  return (
    <Router>
      <div style={styles}>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/etiv">
            <Vite />
          </Route>
          <Route path="/lerriuqs">
            <Squirrel />
          </Route>
          <Route path="/eebla">
            <Albee />
          </Route>
          <Route path="/sikh-wedding">
            <SikhWedding />
          </Route>
          <Route path="/vite-ramen-jokes-1">
            <ViteRamenJokes1 />
          </Route>
          <Route path="/sail-ebrate">
            <Cruise2024 />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
