import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const styles = {
  display: "flex",
  flexDirection: "column",
  backgroundColor: "silver",
  width: "100%",
  height: "100%",
  alignItems: "center"
}

function Container(props) {
  return (
    <div style={styles}>{props.children}</div>
  )
}

export default Container;
