import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function Home() {
  return (
    <div style={{margin: "auto", width: "100%"}}>
      <img style={{width: "50%"}} src="https://firebasestorage.googleapis.com/v0/b/verysuspiciouslink-feb4f.appspot.com/o/big%20decisions.png?alt=media&token=f0c8d059-8152-4a5f-ba71-024ee95eeaad" />
    </div>
  )
}

export default Home;
