import React from "react";

const styles = {
  display: "flex",
  flexGrow: 1,
  padding: "1%",
  height: "auto",
  width: "100%",
  height: "100%"
}

function Meme(props) {
  return (
    <div style={styles}>
      <img style={{width: "100%", objectFit: "contain"}} src={props.url} />
    </div>
  )
}

export default Meme;
