import React from "react";
import Container from "../components/Container";
import Meme from "../components/Meme";
import Title from "../components/Title";

function Squirrel() {
  return (
    <Container>
      <Title>Happy Valentine's day Squirrel!</Title>
      <Meme url="https://firebasestorage.googleapis.com/v0/b/verysuspiciouslink-feb4f.appspot.com/o/bobby.jpg?alt=media&token=b62bb16f-d912-4500-a644-7f2c2647b601" />
      <Meme url="https://firebasestorage.googleapis.com/v0/b/verysuspiciouslink-feb4f.appspot.com/o/ryan.jpg?alt=media&token=833733d8-642d-43d9-ae05-40f5ce3299b8" />
      <Meme url="https://firebasestorage.googleapis.com/v0/b/verysuspiciouslink-feb4f.appspot.com/o/louis.jpg?alt=media&token=d6e039a8-b80e-4757-8543-7406772227f4" />
    </Container>
  )
}

export default Squirrel;
