import React from "react";
import Container from "../components/Container";
import Meme from "../components/Meme";
import Title from "../components/Title";

function ViteRamenJokes1() {
  return (
    <Container>
      <Title>Ryan thinks Nick is funny and encouraged him to make this</Title>
      <Meme url="https://firebasestorage.googleapis.com/v0/b/verysuspiciouslink-feb4f.appspot.com/o/ramen-love-story.jpg?alt=media&token=cbff623d-fa9d-4792-b814-05cbcb4526d5" />
      <Meme url="https://firebasestorage.googleapis.com/v0/b/verysuspiciouslink-feb4f.appspot.com/o/vite-meme-the-beef.jpg?alt=media&token=8ebf8eea-b2e4-442f-a71d-9044ca720e00" />
    </Container>
  )
}

export default ViteRamenJokes1;
